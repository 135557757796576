.nav {
  position: fixed;
  top: -100vh;
  left: 0;
  z-index: 100000;
  width: 100vw;
  height: 100vh;
  padding-top: 50px;
  background: #f7f2f0;
  // overflow: hidden;

  //filter
  &--filter {
    position: absolute;
    left: 0;
    top: 100%;

    width: 100%;
    height: 100vh;

    background-color: black;
    pointer-events: none;

    opacity: 0;
    will-change: opacity;
  }

  &--container {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  &--element {
    margin-top: 70px;
    text-align: center;
  }

  &--number {
    font-family: 'Noe';
    font-size: 16px;
    color: var(--grey);
    letter-spacing: 2px;

    a {
      padding: 10px;
    }
  }

  &--title {
    position: relative;
    margin-top: 30px;
    font-family: 'Noe';
    font-weight: bold;
    font-size: 46px;
    color: var(--black);

    a {
      position: relative;
      transition: color 0.5s $out-cubic;

      .link-subcontent {
        position: absolute;
        left: 0;
        top: -100%;
      }

      &:hover {
        color: var(--red);
      }
    }
  }

  &--links {
    width: 50%;
    margin: 0 auto;
    padding-top: 10em;
  }

  &--link {
    margin: 0 20px;
    overflow: hidden;

    font-family: 'Noe';
    line-height: 18px;

    a, div {
      position: relative;
      display: inline-block;

      &:before {
        content: attr(data-content);
        position: absolute;
        left: 0;
        top: 100%;
      }

      transition: transform 1s $out-quint;

      &:hover {
        transform: translateY(-100%);
      }
    }
  }

  &--cross {
    height: 40px;
    width: 40px;
    position: absolute;
    top: 80px;
    right: 5vw;

    .navbar__bar-container{
      position: relative;
      height: 40px;
      width: 40px;
      &:hover .bar {
         background:  var(--red);
      }

      .bar {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 40px;
        height: 2px;
        background: var(--black);
      transition: background .3s $out-quint;
      }

      .bar-1 {
        transform: rotate(-45deg);
      }
  
      .bar-2 {
        transform: rotate(45deg);
      }
    }
  }
}