.shop {
  width: 100vw;
  height: 100vh;
  position: absolute;
  padding: 0 20%;
  top: 0;
  background: var(--background);
  transform: translateY(-100vh);
  z-index: 1;

  &--left, &--right {
    margin-top: 160px;
  }

  &--title {
    width: 22vw;
    margin-top: 50px;
    font-family: 'Noe';
    font-weight: bold;
    color: var(--red);
    font-size: 92px;
  }

  &--text {
    width: 22vw;
    margin-top: 60px;
    font-family: 'Noe';
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: var(--black);
  }

  &--purchase {
    --w: 250px;
    --h: 50px;
    width: var(--w);
    height: var(--h);
    padding-top: calc(var(--h) / 3);
    display: block;
    margin-top: 50px;
    text-align: center;
    text-transform: uppercase;
    color: var(--black);
    font-style: italic;
    position: relative;

    svg {
      width: var(--w);
      height: var(--h);
      position: absolute;
      top: 0;
      left: 0;

      rect {
        width: var(--w);
        height: var(--h);
        stroke: var(--black);
        stroke-width: 3px;
        stroke-dasharray: 680px;
        stroke-dashoffset: 680px;
        fill: transparent;
      }
    }
  }

  &--image {
    width: 25vw;

    img {
      width: 100%;
    }
  }
}