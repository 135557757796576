* {
  // cursor: none;

  @media #{$mobile} {
    cursor: auto;
  }
}

::selection {
  background: var(--red); /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: var(--red); /* Gecko Browsers */
}

body {
  width: 100vw;
  min-height: 100vh;
  background: var(--white);
  overflow-x: hidden;

  &.no-scroll {
    overflow: hidden;
  }
}

a {
  text-decoration: none;
}

.smooth-scroll {
  height: 100vh;
  width: 100vw;
  overflow: auto;
  position: relative;
  z-index: 1;
}

.desktop {
  @media #{$mobile} {
    display: none;
  }
}

.mobile {
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  overflow: hidden;
  display: none;
  position: relative;

  @media #{$mobile} {
    display: block;
  }
}

.js--block-scroll-content {
  height: 100vh;
  overflow: hidden;
}