.mobile-container {
  width: 80%;
  margin: 0 auto;

  .mobile-header {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header--title--text,
  .header--title--bar,
  .header--dot {
    transform: none;
  }

  .mobile-background {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(60%, 22%);
    max-height: 100%;
    z-index: -10;
  }

  .mobile-content {
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translateY(-50%);

    &--title {
      font-family: 'Noe';
      font-weight: bold;
      font-size: 45px;
      color: var(--red);
    }

    &--text {
      font-family: 'Noe';
      font-style: italic;
      line-height: 1.5;
      font-size: 14px;
      margin-top: 30px;
    }
  }

  .aside-left--play-button {
    position: absolute;
    left: 10%;
    bottom: 30px;

    &:after {
      left: 65px;
    }
  }

  .aside-left--play-button svg>circle {
    stroke-dasharray: 0;
    stroke-dashoffset: 0;
  }

  .triangle {
    opacity: 1;
  }

  .mobile-vid-full {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    top: 0;
    background: black;
    z-index: 10;
    display: none;
  }
}

.js-cursor {
  @media #{$mobile} {
    display: none;
  }
}

.js-pointer {
  @media #{$mobile} {
    display: none;
  }
}