$duration: 2s;

html {
    background-color: black;

    &.is-ready {
        transition: background-color $duration ease-in-out;
        background-color: transparent;
    }
}

body {
    opacity: 0;

    &.is-ready {
        transition: opacity $duration ease-in-out;
        opacity: 1;
    }
}