.project {
    height: 100vh;
    width: 100vw;

    position: fixed;
    top: 0;
    left: 0;

    z-index: 1;

    visibility: hidden;
    opacity: 0;

    display: flex;
    justify-content: center;
    align-items: center;
}

.project__background {
    position: absolute;
    left: 0;
    top: 0;

    width: 100vw;
    height: 100vh;

    background-image: url('../images/background-project.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.project__container {
    position: relative;

    width: 100vw;
    height: 100%;

    padding: 40vh 20vw;

    box-sizing: border-box;
    
    z-index: 1;
}

.project__content-container {
    display: inline-block;
    
    width: 100%;
    margin-left: 50%;
}

.project__heading {
    white-space: nowrap;
    margin-bottom: 30px;

    overflow: hidden;

    font-family: 'Noe';
    font-weight: bold;
    font-size: 92px;
    color: var(--red);
}

.project__paragraph {
    width: 50%;
    max-width: 480px;
    margin-bottom: 40px;

    font-family: 'Noe';
    font-size: 16px;
    line-height: 24px;
    color: var(--black);
}

.project__link {
    position: relative;

    width: 160px;

    padding-left: 70px;
    padding-top: 20px;
    padding-bottom: 20px;

    font-family: 'Noe';
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: var(--black);

    span {
        display: inline-block;

        transform: translateX(-20px);
        opacity: 0;

        transition: transform 1s $out-quint;
    }

    &:before {
        content: '';

        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;

        height: 0px;
        width: 50px;

        margin: auto;

        border: solid 1px var(--black);

        transform: scaleX(0);

        transition: width 1s $out-quint, transform 1s $out-quint;
    }

    &.transition-in {
        span {
            transform: translateX(0);
            opacity: 1;
        }

        &:before {
            transform-origin: left top;
            transform: scaleX(1);
        }
        
        &:hover {
            span {
                transform: translateX(-20px);
            }
    
            &:before {
                width: 30px;
            }
        }
    }
}