.intro {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: var(--white);
  z-index: 100001;

  &--hidden {
    display: none;
  }

  &__container {
    text-align: center;
  }

  p {
    font-family: 'Noe';
    font-size: 33px;
    text-align: center;
    margin: 10px 0;

    div {
      margin: -0.04px;
    }
  }
}

.intro__cta {
  position: relative;

  margin: auto;
  margin-top: 100px;

  padding-bottom: 20px;

  color: var(--red);
  font-size: 13px;
  font-family: 'Noe';
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1.5px;

  &:before {
    position: absolute;
    right: 0;
    bottom: 0;

    width: 100%;
    height: 0;

    border-top: 1px solid var(--red);

    content: '';

    transform: scaleX(0);
    transition: width 0.8s $in-out-quint 0.2s, transform 1.2s $in-out-quint;
  }

  &:after {
    position: absolute;
    left: 0;
    bottom: 0;

    width: 0;
    height: 0;

    
    border-top: 1px solid var(--red);
    
    content: '';
    
    transform: scaleX(1);
    transition: width 0.8s $in-out-quint, transform 1.2s $in-out-quint;
  }

  &.is-active {
    &:before {
      transform-origin: left top;
      transform: scaleX(1);
    }

    // &:after {
    //   transform-origin: left top;
    //   transform: scaleX(1);
    // }
  }

  &:hover {
    &:before {
      width: 0;

      transition: width 0.8s $in-out-quint;
    }

    &:after {
      width: 100%;

      transition: width 0.8s $in-out-quint 0.2s;
    }
  }
}