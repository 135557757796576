.vid-full {
  position: fixed;
  z-index: 100000;
  width: 100vw;
  height: 0vh;
  bottom: 0;
  overflow: hidden;
  display: none;

  &__video {
    position: absolute;
    min-width: 110%;
    min-height: 110%;
    background-color: #000000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.playing {
    display: block;
  }
}