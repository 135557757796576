.home {
  width: 0vw;
  height: 100vh;
  position: absolute;
  top: 0;
  overflow-x: hidden;

  &--screen-size {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
  }
  &--container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80vw;
    height: 100vh;
    margin: auto;
    overflow: hidden;
  }
  &--vid{
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto; height: auto;
    z-index: -1000;
    opacity: 0;
  }
  &--silence{
    display: none;
  }
  &--title {
    // margin-top: calc(30vh);
    margin-left: 20vw;
    font-family: 'Noe';
    font-weight: bold;
    font-size: 95px;
    color: var(--red);

    .hidden-wrap {
      overflow: hidden;
      div { transform: translateY(100%) }
    }

    @media #{$mobile} {
      margin-left: 10vw;
      font-size: 64px;
    }
  }
  &--link {
    position: relative;
    width: 160px;
    padding-left: 70px;
    padding-top: 20px;
    padding-bottom: 20px;
    font-family: 'Noe';
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: var(--black);
    margin-left: 20vw;
  
    span {
        display: inline-block;
        transform: translateX(-20px);
        opacity: 0;
        transition: transform 1s $out-quint;
    }
  
    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        height: 0px;
        width: 50px;
        margin: auto;
        border: solid 1px var(--black);
        transform: scaleX(0);
        transition: width 1s $out-quint, transform 1s $out-quint;
    }
  
    &.transition-in {
        span {
            transform: translateX(0);
            opacity: 1;
        }
  
        &:before {
            transform-origin: left top;
            transform: scaleX(1);
        }
        
        &:hover {
            span {
                transform: translateX(-20px);
            }
    
            &:before {
                width: 30px;
            }
        }
    }
  }
  &--vid-controls{
    width: 30vw;
    position: fixed;
    bottom: 80px;
    right: 30vw;
    margin: auto;

    .home--vid-progress{
      float: right;
      display: inline-block;
      width: 314px;
      height: 2px;
      padding: 0;
      background: #e3d8d5;
      color: var(--red);
      border: 0;
      transform: scaleX(0);
      transition: transform 1s $in-out-quint;
      transform-origin: left;
      &::-moz-progress-bar {
        background: #e3d8d5;
      }
      &::-moz-progress-value {
        background: var(--red);
      }
      &::-webkit-progress-bar {
        background: #e3d8d5;
      }
      &::-webkit-progress-value {
        background: var(--red);
      }
    }
/*
    .home--vid-progress::-moz-progress-bar{
      float: right;
      display: inline-block;
      width: 300px;
      height: 20px;
      padding: 3px 3px 2px 3px;
      background: var(--red);
      fill: var(--red);
    }*/
  }

}