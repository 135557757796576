@mixin text-rendering {
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -khtml-font-smoothing: antialiased;
    -apple-font-smoothing: antialiased;
    font-smooth: always;
    -moz-osx-font-smoothing: grayscale;
}

body {
    @include text-rendering();
}