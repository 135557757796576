@font-face {
  font-family: 'Noe';
  src: url('../fonts/noe-display-regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Noe';
  src: url('../fonts/noe-display-bold.ttf') format('truetype');
  font-weight: bold;
}