.header {
  width: 90vw;
  position: fixed;
  top: 80px;
  left: 5vw;
  z-index: 10000;

  &--dot {
    margin-top: -11px;
    width: 44px;
    height: 44px;
    transform: scale(0);
    position: relative;
  }

  &--dot-circle {
    width: 44px;
    height: 44px;
    border-radius: 100%;
    background: var(--red);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1);
  }

  &--dot:hover &--dot-circle {
    transform: translate(-50%, -50%) scale(0.2);
  }

  &--dot-svg-container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 44px;
    height: 44px;
    transform: translate(-50%, -50%);
    animation: spin 4s linear infinite;
    opacity: 0.7;
  }

  &--dot svg {
    transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    width: 70px;
    height: 70px;
  }

  &--dot:hover svg {
    transform: translate(-50%, -50%) scale(1);
  }

  &--title {
    &--bar {
      height: 2px;
      width: 60px;
      display: inline-block;
      background: var(--black);
      transform-origin: left;
      transform: scaleX(0);
    }

    &--text {
      margin-left: 50px;
      display: inline-block;
      font-family: "Noe";
      font-size: 16px;
      font-weight: bold;
      color: var(--black);
      transform: translateY(100%);
    }
  }

  &--shop,
  &--prevention {
    display: flex;
    justify-content: center;
    align-items: baseline;

    &--text {
      font-family: "Noe";
      font-size: 14px;
      line-height: 22px;
      font-weight: normal;
      color: var(--black);
      transform: translateY(calc(100% + 2px));

      a {
        padding-left: 15px;
        position: relative;
        color: var(--black);
        display: inline-block;
        transition: transform 1s $out-quint;
        &:before {
          padding-left: 15px;
          content: attr(data-content);
          position: absolute;
          left: 0;
          top: 100%;
        }
        &:hover {
          transform: translateY(-100%);
        }
      }
    }

    &--dot {
      display: inline-block;
      transform: translateY(-2px);
      height: 4px;
      width: 4px;
      border-radius: 50%;
      background: var(--black);
      opacity: 0;
    }
  }

  &--burger {
    &:hover  &--lower-bun, &:hover  &--upper-bun {
      background: var(--red);
    }
    &--upper-bun {
      width: 40px;
      height: 2px;
      background: var(--black);
      transform-origin: left;
      transform: scaleX(0);
    transition: background .3s $out-quint;

    }

    &--lower-bun {
      width: 25px;
      height: 2px;
      margin-top: 10px;
      margin-left: 15px;
      background: var(--black);
      transform-origin: left;
      transform: scaleX(0);
    transition: background .3s $out-quint;

    }
  }
}

.aside-left {
  height: calc(100vh - 200px);
  position: fixed;
  left: 0;
  bottom: 80px;
  z-index: 1000;
  left: 5vw;
  font-family: "Noe";

  .end {
    flex-direction: column;
    height: 100%;
    align-items: baseline;
  }

  &--home {
    margin-top: 10px;

    &--text {
      font-family: "Noe";
      font-size: 14px;
      font-weight: normal;
      color: var(--black);
      transform: translateY(calc(100% + 2px));
      position: relative;
      display: inline-block;
      opacity:0;
    }

    &--dot {
      height: 5px;
      width: 5px;
      margin: 15px auto;
      border-radius: 50%;
      background: var(--black);
      opacity:0;
      transform: scale(0);
    }
  }

  &--link {
    transform: rotate(-90deg);
  }

  &--play-button {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;

    &:hover &--text{
      transform: translateX(-20px);
    }

    &:hover svg, &:hover .triangle  {
      transform: scale(0.9);
    }

    &--text {
      margin-left: 30px;
      opacity:0;
      transition: transform .8s $out-quint;

    }

    svg {
      transition: transform .8s $out-quint;
    }

    svg > circle {

      stroke: var(--red);
      stroke-width: 2px;
      fill: transparent;
      stroke-dasharray: 500px;
      stroke-dashoffset: 500px;
      transition: all 3s;
    }

    .triangle {
      width: 0;
      height: 0;
      position: absolute;
      top: 17px;
      left: 20px;
      border-style: solid;
      transition: transform .8s $out-quint;
      border-width: 6px 0px 5px 6px;
      border-color: transparent transparent transparent var(--red);
      opacity: 0;
    }
  }
}

.aside-right {
  height: calc(100vh - 200px);
  position: fixed;
  right: 5vw;
  bottom: 80px;
  z-index: 10000;
  font-family: "Noe";

  .flex {
    flex-direction: column;
    height: 100%;
    align-items: flex-end;
  }

  &--chapter {
    margin-bottom: 18px;
    transform: rotate(90deg);

    &--hide {
      visibility: hidden;
    }

    &--text {
      font-family: "Noe";
      font-size: 14px;
      font-weight: normal;
      color: var(--black);
      transform: translateY(calc(100% + 2px));
      opacity:0;
      a {
        position: relative;
        color: var(--black);
        display: inline-block;
      }
    }

    &--dot {
      height: 5px;
      width: 5px;
      margin: 15px auto;
      border-radius: 50%;
      opacity:0;
      background: var(--black);
    }
  }

  &--sound-button {
    height: 25px;
    width: 25px;
    transform-origin: bottom;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    transform: scaleY(0);

    .bar {
      display: inline-block;
      height: 5px;
      width: 2px;
      margin-top: 4px;
      background: var(--black);
      animation-duration: 0.6s;
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }

    .bar:nth-child(1) {
      animation-name: volume-low;
      animation-delay: 0.2s;
    }

    .bar:nth-child(2) {
      animation-name: volume;
      animation-delay: 0.6s;
    }

    .bar:nth-child(3) {
      animation-name: volume-high;
      animation-delay: 0.1s;
    }

    .bar:nth-child(4) {
      animation-name: volume-low;
      animation-delay: 0.3s;
    }

    .bar:nth-child(5) {
      animation-name: volume;
      animation-delay: 0.5s;
    }

    .bar.bar--paused {
      animation-name: none;
      height: 2px;
    }
  }
}

@keyframes volume {
  0% {
    height: 20%;
  }

  100% {
    height: 80%;
  }
}

@keyframes volume-low {
  0% {
    height: 0%;
  }

  100% {
    height: 40%;
  }
}

@keyframes volume-high {
  0% {
    height: 30%;
  }

  100% {
    height: 100%;
  }
}

@keyframes spin {
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
