.js-cursor {
  width: 40px;
  height: 40px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100005;
  transform-origin: center;
  transform: translateX(50vw) translateY(50vh);
  pointer-events: none;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  svg.cross {
    transform: translate(-50%, -50%) scale(3);
  }

  .arrow-left {
    height: 0px;
    width: 0px;
    border: 4px solid var(--red);
    border-left-color: transparent;
    border-bottom-color: transparent;
    border-top-color: transparent;
    top: 50%;
    left: 8px;
    position: absolute;
    transform: translateY(-50%);

    opacity: 0;
  }

  .arrow-right {
    height: 0px;
    width: 0px;
    border: 4px solid var(--red);
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-top-color: transparent;
    top: 50%;
    right: 8px;
    position: absolute;
    transform: translateY(-50%);

    opacity: 0;
  }

  svg circle {
    stroke: var(--red);
    stroke-width: 2px;
    fill: transparent;
    stroke-dasharray: 500px;
    stroke-dashoffset: 500px;
  }

  svg path {
    stroke: var(--red);
    stroke-dasharray: 500px;
    stroke-dashoffset: 500px;
  }

  svg path:nth-child(1) {
    stroke-dasharray: 250px;
    stroke-dashoffset: 250px;
  }
}