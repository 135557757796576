:root {
  --white: #faf5ed;
  --background: #f0e5e1;
  --red: #ff1e23;
  --black: #000000;
  --grey: #808080;
}

$mobile: '(max-width: 1024px)';

$in-out-cubic: cubic-bezier(0.65, 0, 0.35, 1);
$in-out-quint: cubic-bezier(0.83, 0, 0.17, 1);

$out-cubic: cubic-bezier(0.33, 1, 0.68, 1);
$out-quint: cubic-bezier(0.22, 1, 0.36, 1);

$in-cubic: cubic-bezier(0.32, 0, 0.67, 0);
$in-quint: cubic-bezier(0.64, 0, 0.78, 0);