.chapter {
  position: fixed;
  width: 0vw;
  right: 0;
  top: 0;
  overflow-x: hidden;
  z-index: 1;

  &-static {
    position: static;
    z-index: auto;
  }

  &-previous {
    left: 0;
    right: auto;
  }

  &-previous &--screen-width {
    left: 0;
    right: auto;
  }

  &--screen-width {
    width: 100vw;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
  }

  &--container {
    width: 100vw;
    padding: 40vh 20vw 30vh 20vw;
    background: var(--background);
    position: relative;
    z-index: 1;
  }

  &--number {
    font-family: "Noe";
    font-weight: bold;
    font-size: 92px;
    color: var(--red);

    @media #{$mobile} {
      font-size: 64px;
    }
  }

  &--part {
    margin-top: 325px;

    &.part-left {
      .chapter--subtitle {
        text-align: left;
      }
      .chapter--text {
        margin-left: auto;
      }
    }

    &.part-right {
      .chapter--subtitle {
        text-align: right;
      }
      .chapter--text {
        margin-right: auto;
      }
    }
  }

  &--part-next-to-slider {
    margin-top: 600px;
  }

  &--title {
    display: block;
  }

  &--part-low-speed-parallax {
    margin-top: 500px;
  }

  &--part-medium-speed-parallax {
    margin-top: 450px;
  }

  &--name {
    margin: 50px 100px;
    font-family: "Noe";
    font-weight: bold;
    font-size: 26px;
    color: var(--black);
    position: relative;
    will-change: transform;

    &--bar {
      width: 50px;
      height: 3px;
      position: absolute;
      left: -100px;
      top: 10px;
      background: var(--black);
      transform-origin: left;
      transform: scaleX(0);
      will-change: transform;

      @media #{$mobile} {
        height: 2px;
        width: 40px;
        left: -50px;
      }
    }

    @media #{$mobile} {
      font-size: 18px;
      margin: 50px 0 0 50px;
    }
  }

  &--subtitle {
    font-family: "Noe";
    font-weight: bold;
    font-size: 60px;
    color: var(--black);

    @media #{$mobile} {
      font-size: 36px;
    }
  }

  &--text {
    width: 70%;
    margin-top: 100px;
    font-family: "Noe";
    font-size: 33px;
    line-height: 45px;
    color: var(--black);
    opacity: 1;

    @media #{$mobile} {
      width: 100%;
      font-size: 20px;
      line-height: 28px;
    }

    &.text-large {
      width: 920px;
    }
  }

  &--image {
    &.image-intro--chest {
      width: 819px;
      height: 1102px;
      top: 320px;
      right: 0;
      z-index: -10;
      position: absolute;

      .chapter--image__tag {
        background: url("../images/bonhomme.png");
        background-size: contain;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    &.image-intro--hand {
      width: 889px;
      height: 340px;
      top: 160px;
      right: -100px;
      z-index: -10;
      position: absolute;

      .chapter--image__tag {
        background: url("../images/main-baguette.png");
        background-size: contain;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform-origin: 100% 50%;
      }
    }

    &.image-intro--red-medicine {
      width: 615px;
      height: 345px;
      top: 420px;
      right: 270px;
      z-index: -10;
      position: absolute;

      .chapter--image__tag {
        background: url("../images/medicaments-rouge.png");
        background-size: contain;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    &.image-hand {
      width: 596px;
      height: 419px;
      top: 1270px;
      left: 0;
      z-index: 2;
      position: absolute;

      .chapter--image__tag {
        background: url("../images/main.png");
        background-size: contain;
        transform-origin: left 20px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    &.image-point {
      width: 45px;
      height: 45px;
      top: 1730px;
      left: 420px;
      z-index: 1;
      position: absolute;

      .chapter--image__tag {
        background: url("../images/bille.png");
        background-size: contain;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    &.image-point-floating {
      width: 45px;
      height: 45px;
      top: 1730px;
      left: 420px;
      z-index: 1;
      position: absolute;

      .chapter--image__tag {
        background: url("../images/bille.png");
        background-size: contain;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      &--first {
        top: 1119px;
        left: 480px;
      }

      &--second {
        top: 1920px;
        right: 590px;
        left: auto;
      }

      &--last {
        top: 2750px;
        left: 450px;
      }
    }

    &.image-bol--top {
      width: 288px;
      height: 342px;
      position: absolute;
      top: 2460px;
      right: 100px;

      .chapter--image__tag {
        background: url("../images/BOL-DE-BILLES-2.png");
        background-size: contain;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    &.image-bol--top {
      width: 288px;
      height: 342px;
      position: absolute;
      top: 2460px;
      right: 100px;
      z-index: -10;

      .chapter--image__tag {
        background: url("../images/BOL-DE-BILLES-2.png");
        background-size: contain;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    &.image-bol--bottom {
      width: 254px;
      height: 189px;
      position: absolute;
      top: 2625px;
      right: 100px;
      z-index: 2;

      .chapter--image__tag {
        background: url("../images/BOL-DE-BILLES-1.png");
        background-size: contain;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    &.image-box {
      width: 395px;
      height: 362px;
      position: absolute;
      top: 3280px;
      left: 220px;
      z-index: -10;

      .chapter--image__tag--first-box {
        background: url("../images/boite-1.png");
        background-size: contain;
        position: absolute;
        top: 2px;
        width: 395px;
        height: 360px;
        z-index: 1;
      }

      .chapter--image__tag--second-box {
        background: url("../images/boite-2.png");
        background-size: contain;
        position: absolute;
        bottom: 0;
        width: 395px;
        height: 334px;
        z-index: 3;
      }

      .chapter--image__tag--point {
        background: url("../images/bille.png");
        background-size: contain;
        position: absolute;
        top: 20%;
        left: 126px;
        z-index: 2;
        width: 45px;
        height: 45px;
        animation-duration: 0.8s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
        animation-name: box-point;
      }
    }

    &.image-brain {
      width: 932px;
      height: 934px;
      top: 4190px;
      right: -110px;
      z-index: -10;
      position: absolute;

      .chapter--image__tag--brain {
        background: url("../images/Cerveaux_cerveaux.png");
        background-size: contain;
        position: absolute;
        width: 892px;
        height: 924px;
        bottom: 0;
        left: 0;
      }

      .chapter--image__tag--hand {
        background: url("../images/Cerveaux_main.png");
        background-size: contain;
        position: absolute;
        width: 645px;
        height: 690px;
        top: 0;
        right: 0;
      }
    }

    &.image-balance {
      width: 799px;
      height: 550px;
      top: 1210px;
      left: -140px;
      z-index: -10;
      position: absolute;

      .chapter--image__tag--back {
        background: url("../images/balance-1.png");
        background-size: contain;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 608px;
        height: 550px;
      }

      .chapter--image__tag--rotate {
        background: url("../images/balance-2.png");
        background-size: contain;
        position: absolute;
        width: 799px;
        height: 439px;
        bottom: 80px;
        left: -100px;
      }
    }

    &.image-heart {
      z-index: -10;
      position: absolute;
      top: 2380px;
      right: -60px;
      z-index: -10;
      width: 725px;
      height: 328px;

      .chapter--image__tag {
        background: url("../images/coeur.png");
        background-size: contain;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    &.image-wound {
      width: 794px;
      height: 768px;
      position: absolute;
      top: 3180px;
      left: 0;
      z-index: -10;

      .chapter--image__tag {
        background: url("../images/baguette.png");
        background-size: contain;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    &.image-magnifying-glass {
      width: 595px;
      height: 377px;
      top: 4430px;
      right: -110px;
      z-index: -10;
      position: absolute;
      .chapter--image__tag {
        background: url("../images/main-loupe.png");
        background-size: contain;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    &.image-bag {
      width: 679px;
      height: 802px;
      top: 920px;
      left: 0;
      z-index: -10;
      position: absolute;

      .chapter--image__tag {
        background: url("../images/malette.png");
        background-size: contain;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    &.image-zoom {
      z-index: -10;
      position: absolute;
      top: 1960px;
      right: -60px;
      z-index: -10;
      width: 593px;
      height: 374px;

      .chapter--image__tag {
        background: url("../images/loupe.png");
        background-size: contain;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    &.image-medicine {
      width: 407px;
      height: 437px;
      position: absolute;
      top: 2610px;
      left: 220px;
      z-index: -10;

      .chapter--image__tag {
        background: url("../images/medicaments.png");
        background-size: contain;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    &.image-blue-medicine {
      width: 612px;
      height: 934px;
      top: 3030px;
      right: 0;
      z-index: -10;
      position: absolute;

      .chapter--image__tag--hand {
        background: url("../images/mail-medicament.png");
        background-size: contain;
        position: absolute;
        right: 0;
        top: 0;
        width: 410px;
        height: 461px;
      }

      .chapter--image__tag--medicine {
        background: url("../images/medicaments-bleu.png");
        background-size: contain;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 492px;
        height: 363px;
      }
    }

    &.image-tools {
      width: calc(100% - 20vw);
      height: 351px;
      top: 4600px;
      left: 100px;
      z-index: -20;
      position: absolute;

      .chapter--image__tag--hand {
        position: relative;
        &__container {
          position: absolute;
          left: 80%;
          top: 0;
          transform: translateX(-50%);
          width: 217px;
          height: 583px;
          background: url("../images/main-outil.png");
          background-size: contain;
        }
      }
    }
  }

  &--next {
    margin-top: 35vh;
    color: var(--black);

    &--text {
      font-family: "Noe";
      font-size: 140px;
      font-weight: bold;
      text-align: center;
      position: relative;
      z-index: -15;
      text-transform: uppercase;
      div {
        transition: color 0.5s ease-out;
      }
    }

    &:hover div {
      color: var(--red);
    }
  }

  &--next-end-by-slider {
    margin-top: calc(30vh + 275px);
  }

  &--symptoms {
    position: absolute;
    left: 0;
    // width: 100vw;
    margin-top: 150px;
    margin-bottom: 400px;
    white-space: nowrap;
    color: var(--red);
    transition: color 0.3s ease-in-out;
    &:hover {
      color: var(--black);
      ul:after {
        color: var(--black);
      } 
    }
    ul {
      .symptoms--wrapper {
        pointer-events: none;
      }
    }
  }



  &--symptom {
    display: inline-block;
    margin: 0;
    pointer-events: none;
    margin-right: 120px;
    font-family: "Noe";
    font-weight: bold;
    font-size: 105px;
    text-transform: uppercase;
    user-select: none;

    &:last-child {
      margin-right: 0;
    }
    &:first-child {
      margin-left: 60px;
    } 
  }

  &--slider__dragger {
    height: 50px;
    width: 50px;
    border: 2px solid var(--red);
    border-radius: 50%;
    display: inline-block;
    position: relative;
    margin-left: 20px;
    -webkit-user-drag: none;
    user-select: none;
  }

  &--slider__dragger-dot {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5px;
    border-radius: 50%;
    background-color: var(--red);
    height: 5px;
    transform: translate(-50%, -50%);
  }

  &--slider__dragger-left-arrow {
    height: 0px;
    width: 0px;
    border: 3px solid var(--red);
    border-left-color: transparent;
    border-bottom-color: transparent;
    border-top-color: transparent;
    top: 50%;
    left: 8px;
    position: absolute;
    transform: translateY(-50%);
  }

  &--slider__dragger-right-arrow {
    height: 0px;
    width: 0px;
    border: 3px solid var(--red);
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-top-color: transparent;
    top: 50%;
    right: 8px;
    position: absolute;
    transform: translateY(-50%);
  }

  &--slider__progress {
    position: absolute;
    right: 20px;
    width: 180px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 1px;
    background-color: black;
    pointer-events: none;
  }

  &--slider__progress-value {
    position: absolute;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;

    background-color: var(--red);
    transform: scaleX(0);
    will-change: transform;
    transform-origin: left top;
  }

  &--slider__length {
    display: inline-block;
    font-family: "Noe";
    font-size: 18px;
    vertical-align: middle;
    pointer-events: none;
    user-select: none;
  }

  &--slider {
    display: flex;
  }

  &--slider__aside__right {
    margin-left: 30%;
  }

  &--slider__control {
    overflow: hidden;
    width: 160px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    box-sizing: content-box;
    padding: 10px 20px;
  }

  &--slider ul {
    position: absolute;
    left: 30%;
    top: 0;
    width: max-content;
  }

  // &--slider ul:after{
  //   content: "Irritabilité";
  //   transition: color 0.3s ease-in-out;
  //   display: block;
  //   color: var(--red);
  //   font-size: 105px;
  //   font-family: "Noe";
  //   font-weight: bold;
  //   text-transform: uppercase;
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   transform: translateX(-100%);
  //   padding-right: 120px;
  // }

  // &--slider ul.hyperthyroid:after {
  //   content: "Tremblement";
  // }
}

@keyframes box-point {
  0% {
    transform: transform3d(0, 0, 0);
  }

  100% {
    transform: translate3d(0, -270px, 0);
  }
}
