.bold { font-weight: bold }

.flex {
  display: flex;

  &.evenly {
    justify-content: space-evenly;
  }

  &.between {
    justify-content: space-between;
  }

  &.around {
    justify-content: space-around;
  }

  &.center {
    justify-content: center;
  }
}

.hidden-wrap {
  overflow: hidden;

  &.inline {
    display: inline-block;
  }

  &.middle {
    vertical-align: middle;
  }
}

.inline-block { display: inline-block }
.block { display: block }